
const About = () => { 

	return(
		<div className="about-div">
			{/* <h2>About Me</h2> */}
			<h2>My Resume</h2>
			<embed src="https://drive.google.com/file/d/1XjCGPQmWhZuJEoAc1cFj5SVMZIUXkWCM/preview" width="640" height="480"/>
		</div>
	)
}

export default About;